import React from 'react';
import HomeMain from './home/HomeMain';
import WhoAreWe from './home/WhoAreWe';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <HomeMain />
      <WhoAreWe />
    </div>
  )
}

export default Home;