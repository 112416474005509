import "./App.css";
import Home from "./components/Home";
import Bookings from "./components/Bookings/Bookings";
import Digital from "./components/Digital/digital";
import Businessprocess from "./components/Business/BusinessProcess";
// import Enquiry from "./components/Enquiries/Enquiry";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import MainHeader from "./components/Bookings/MainHeader";

function App() {
  return (
    <div>
      <MainHeader />
      <div className="main">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="bookings" element={<Bookings />} />
        <Route path="businessprocess" element={<Businessprocess />} />
        {/* <Route path="enquiries" element={<Enquiry />} /> */}
        <Route path="digital" element={<Digital />} /> 
      </Routes>
      </div>
    </div>
  );
}

export default App;
