import React, { useState } from "react";

import "../../css/MainHeader.css";
import { Link, NavLink } from "react-router-dom";
import zen3_logo from "../../asserts/svgs/logo.svg";
import uparrowIcon from "../../asserts/svgs/uparrow.svg";
import dropDownIcon from "../../asserts/svgs/dropdown.svg";
import digitalAssistant from "../../asserts/svgs/digital-assistant.svg";
import box from "../../asserts/svgs/box.svg";
import chatbot from "../../asserts/svgs/chatbot.svg";
import menu_icon from "../../asserts/svgs/menu_icon.svg";

const SampleHeader = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isOpenDropDown, setIsopenDropDown] = useState(false);
    return (
        <div className="header_class">
            <nav>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <img src={zen3_logo} alt="zen3-logo" height={56} />
                </Link>
                <div className="menu" onClick={() => setIsopenDropDown(!isOpenDropDown)}>
                    {
                        isOpenDropDown ?
                            <div style={{fontSize:"34px", marginTop:"-10px"}}> X </div>
                            :
                            <>
                                <span></span>
                                <span></span>
                                <span></span>
                            </>
                    }

                </div>
                <div className="header-left-content">
                    <ul className={menuOpen ? "open" : ""}>
                        <li>
                            <Link to="#" onClick={() => setIsopenDropDown(!isOpenDropDown)}>
                                <div className="btn-Solutions">
                                    <div className="">Solutions</div>
                                    <img src={dropDownIcon} />
                                </div>
                            </Link>
                        </li>
                        <li>
                            <a href="mailto:enquiries@zen3.com" style={{ textDecoration: 'none' }}>
                                <div className="btn-uparrow w-fit-content text">
                                    <div className="">Connect Now</div>
                                    <img src={uparrowIcon} />
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                {isOpenDropDown && (
                    <div className="drop-down-options" onClick={() => setIsopenDropDown(false)}>
                        <div className="close-icon close-symbol" onClick={() => setIsopenDropDown(false)}>X</div>
                        <div className="popup-layers">
                            <div className="card transformation-popup">
                                <div className="pop-info">
                                    <div className="popup-img">
                                        <img src={digitalAssistant} width={56} height={56} />
                                    </div>
                                    <div className="popup-text">
                                        Transformational
                                        Booking & Fulfilment
                                        Platforms
                                    </div>
                                </div>
                                <div className="popup-readmore" onClick={() => { setIsopenDropDown(!isOpenDropDown) }}>
                                    <Link to="/bookings" style={{ textDecoration: 'none', color: "#001233" }}>Read more <img src={uparrowIcon} /></Link>
                                </div>
                            </div>

                            <div className="card transformation-popup">
                                <div className="pop-info">
                                    <div className="popup-img">
                                        <img src={box} width={56} height={56} />
                                    </div>
                                    <div className="popup-text">
                                        business process <br />
                                        outsourcing & <br />
                                        offshoring
                                    </div>
                                </div>
                                <div className="popup-readmore" onClick={() => { setIsopenDropDown(!isOpenDropDown) }}>
                                    <Link to="/businessprocess" style={{ textDecoration: 'none', color: "#001233" }}>Read more <img src={uparrowIcon} /></Link>
                                </div>
                            </div>

                            <div className="card transformation-popup">
                                <div className="pop-info">
                                    <div className="popup-img">
                                        <img src={chatbot} width={56} height={56} />
                                    </div>
                                    <div className="popup-text">
                                        digital <br />
                                        transformation
                                    </div>
                                </div>
                                <div className="popup-readmore" onClick={() => { setIsopenDropDown(!isOpenDropDown) }}>
                                    <Link to="/digital" style={{ textDecoration: 'none', color: "#001233" }}>Read more <img src={uparrowIcon} /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </nav>
        </div>
    );
};

export default SampleHeader;