import React, { useRef } from "react";
import "../../css/home.css";
import downarrowIcon from "../../asserts/svgs/downarrow.svg";
import heroflight from "../../asserts/Images/heroflight.png";
import homebanner from "../../asserts/Images/home banner img desktop.png";
import bulbIcon from "../../asserts/svgs/light.svg";
import bowIcon from "../../asserts/svgs/bow.svg";
import { Link } from 'react-router-dom';
import uparrowIcon from "../../asserts/svgs/uparrow.svg";

const HomeMain = () => {

  const targetRef = useRef(null);

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
    <div className="background-homepage">
      <div className="main-home py">
        <div className="home-image">
          <img src={homebanner} className="homeBannerFirst" alt="flights-icon" width={692} height={320}/>
          <img src={heroflight} className="heroflight" alt="flights-icon" width={300} height={318}/>
        </div>
        <div className="home-text">
          <p className="titles title">
            travel distribution Transformed By 
            <span className="titles"> <br/>Human & Artificial Intelligence</span>
          </p>
          <div className="btn-uparrow w-fit-content coonect-home-desktop" onClick={handleScroll}>
            <div className="text">Scroll Down</div>
            <img src={downarrowIcon} alt="up-arrow-icon" />
          </div>
          <div className="coonect-home-mobile">
          <a href="mailto:enquiries@zen3.com" style={{ textDecoration: 'none'}}>
          <div className="btn-uparrow w-fit-content text">
            <div className="">Connect Now</div>
            <img src={uparrowIcon} />
          </div>
          </a>
        </div>
        </div>
      </div>
    </div>
     <div className="who-are-we-main-content" ref={targetRef}>
     <div className="content-scroll">
     <p className="titles p">Who are we?</p>
     <div className="abt-content">
       <p className="content">
         zen3 is a customer focussed travel solutions company powered by
         Human and Artificial Intelligence, offering contemporary,
         transformative solutions throughout the travel distribution
         ecosystem.
       </p><br />
       <p className="content">
         Delivering superior outcomes to travel industry principals and their
         customers via products which are both revenue enhancing and cost-effective
         via outsourcing, offshoring and a unique combination of technological excellence,
         Human capital and Artificial Intelligence.
       </p>
     </div>
     </div>
     <div className="content-scroll">
     <div className="card">
       <div className="cards">
         <div className="card-title titles mission-img">
           <img src={bowIcon} alt="arrow-iconb" />
           <div className="title-name mission-space">Mission</div>
         </div>
         <div className="card-content">
           Our mission is to empower our clients and their customers with
           Human and Artificial Intelligence, by delivering integrated
           solutions and services that transform travel distribution and
           create value.
         </div>
       </div>
       <div className="cards">
         <div className="card-title titles mission-img">
           <img src={bulbIcon} alt="arrow-iconb" />
           <div className="title-name mission-space">Vision</div>
         </div>
         <div className="card-content">
           Our vision is to be recognised by our clients as the most innovative and effective business partner in travel and travel related distribution, including technology, outsource services in technology, distribution platforms, digital transformation and fulfilment, including BPO services.
         </div>
       </div>
     </div>
     </div>
   </div>
   </>
  );
};

export default HomeMain;
