import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import "../../css/whoarewe.css";
import TravelSaaS from "../../asserts/svgs/Travel SaaS.svg";
import AirlineHolidaysPackaging from "../../asserts/svgs/Airline Holidays Packaging.svg";
import IntermediaryDynamicPackaging from "../../asserts/svgs/Intermediary Dynamic Packaging.svg";
import serviceIcon from "../../asserts/svgs/serviceIcon.svg";
import uparrowIcon from "../../asserts/svgs/uparrow.svg";
import group from "../../asserts/svgs/Group 159.svg";
import groupUp from "../../asserts/svgs/GroupUp.svg";
import businessProcessImg from "../../asserts/svgs/businessProcessImg.svg";
import digitalTramsformImg from "../../asserts/svgs/digital-icon.svg";
import avationImg from "../../asserts/svgs/aviation.svg";
import travelAgencyImg from "../../asserts/svgs/travelAgency.svg";
import dynamicTravelImg from "../../asserts/svgs/dynamicTravel.svg";
import travelManagementImg from "../../asserts/svgs/travelMabnagement.svg";
import cruiseImg from "../../asserts/svgs/cruise.svg";
import onlineTravelImg from "../../asserts/svgs/onlineTravel.svg";
import tourOperatorsImg from "../../asserts/svgs/tourOperations.svg";
import { Link } from 'react-router-dom';

const WhoAreWe = () => {

  const [isExpandedBookings, setIsExpandedBookings] = useState(false);
  const [isExpandedBusinnes, setIsExpandedBusinnes] = useState(false);
  const [isExpandedDigital, setIsExpandedDigital] = useState(false);

  const toggleReadMore = (val) => {
    if (val == "1") {
      setIsExpandedBookings(!isExpandedBookings);
    }
    if (val == "2") {
      setIsExpandedBusinnes(!isExpandedBusinnes);
    }
    if (val == "3") {
      setIsExpandedDigital(!isExpandedDigital);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="who-are-we">
        <p className="titles center">zen3 travel solutions</p>
        <div className="service-card">
          <div className="services bookings">
            <div className="img-service">
              <div className="include-transform-solutions">
                <div className="horizontal-view bookings-background horizontal-spaces mobile_vesrion">
                  <div className="horizontal-item horizontal-transformv mobileV">
                    <div className="">
                      <h3 className="horizontal-title">
                        transformational booking & fulfilment platforms
                      </h3></div>
                    <div className="horizontal-paragraph">
                      <p className="horizontal-content">
                        Zen3 offers a unique approach to the latest generation of Booking & Fulfilment Platforms, offering transformational full content solutions (beyond air) to customers including Airlines (both direct and indirect models); Intermediaries including Travel Agents, OTA’s, TMC’s, Tour Operators & Wholesalers.
                       
                        {isExpandedBookings || (
                          <>
                            {' '}<br/>
                            <span className="readmore_button"><Link to="#" onClick={() => toggleReadMore("1")} style={{ color: "white", cursor: 'pointer' }}>
                              Read more {' '}
                              <img src={groupUp} />
                            </Link>
                            </span>
                          </>
                        )}
                        {isExpandedBookings && (
                          <span>
                             <br/><br/>
                            {" "} Our objective – deliver new generation distribution outcomes notably: Lower cost of distribution for Airlines, with an option of direct ticketing whilst delivering full, readily serviceable content for intermediaries via our proprietary nextgen Travel SaaS dynamic packaging platform.
                            <br />
                            <span><Link to="#" onClick={() => toggleReadMore("1")} style={{ color: 'white', cursor: 'pointer' }}>
                              {' '}
                              Read less  {' '}
                              <img src={group} />
                            </Link>
                            </span>
                          </span>
                        )}
                      </p>

                    </div>
                    <div className="main-small-tabs">
                      <div className="small-tabs">
                        <li className="small-tabs-Travel-text">TRAVEL SaaS</li>
                      </div>
                      <div className="small-tabs">
                        <li className="small-tabs-text">
                          Airline Holidays Packaging
                        </li>
                      </div>
                      <div className="small-tabs">
                        <li className="small-tabs-text">
                          Intermediary Dynamic Packaging
                        </li>
                      </div>
                    </div>
                    <div className="mt-48 coonect-home-desktop">
                      <Link to="/bookings" style={{ textDecoration: 'none' }}>
                        <div className="btn-uparrow w-fit-content">
                          <div className="text">Learn More</div>
                          <img src={uparrowIcon} />
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="horizontal-item mobileT">
                    <img src={serviceIcon} alt="Description of the image" width={600} height={640} />
                  </div>
                </div>

                {/* Product Soluctions */}

                <div className="product-soluctions">
                  <p className="titles name mb-64 product-solution-name">Product solutions include</p>
                  <div className="card-items">
                    <div className="cards">
                      <div className="card-title titles">
                        <img src={TravelSaaS} alt="arrow-iconb" />
                        <div className="title-name">Travel SaaS</div>
                      </div>
                      <div className="content">
                        zen3 solutions for transformational booking & fulfilment
                        platforms all incorporate our proprietary Travel SaaS
                        (Travel Software as a Solution) platform.
                        <br />
                        <br />
                        Travel SaaS enables users to lower the cost of
                        distribution and fulfilment across the travel ecosystem
                        with content solutions enabling Airlines to distribute
                        either direct to customers or through 3rd Party
                        intermediaries, including air and non-air content
                        utilising NDC, API’s, Direct to PSS and where necessary
                        legacy EDIFACT air content.
                      </div>
                    </div>
                    <div className="cards">
                      <div className="card-title titles">
                        <img src={AirlineHolidaysPackaging} alt="dark-light-icon" />
                        <div className="title-name">
                          Airline Holidays Packaging
                        </div>
                      </div>
                      <div className="content">
                        Our airline direct (airline holidays) product enables
                        higher ratios for Airlines of non-airline ancillary revenues via
                        current best practices in Dynamic Packaging. Our
                        purpose, lower operating costs, servicing excellence and
                        improved revenues and margins for Airlines.
                        <br />
                        <br />
                        Our Travel SaaS platform, integrated to Airline booking
                        and ticketing solutions and workflows enables Airlines
                        to distribute either direct to customers or through
                        3rd Party intermediaries, including air and non-air
                        content utilising NDC, API’s, Direct to PSS and where
                        necessary legacy EDIFACT air content.
                        <br />
                        <br />
                        <div className="liness">
                          <li className=""></li>
                          <p> This approach enables Airlines to minimise cost of
                            distribution whilst realising higher levels of non-air
                            ancillary attachment to improve non-air revenues.
                          </p>
                        </div><br />
                        <div className="liness">
                          <li className=""></li>
                          <p>
                            Travel SaaS can deliver non-air content to Airlines
                            via zen3 existing content partners. We offer a range
                            of economic models to Airlines to ensure total
                            transparency.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-items mt-24">
                    <div className="cards">
                      <div className="card-title titles">
                        <img src={IntermediaryDynamicPackaging} alt="dark-light-icon" />
                        <div className="title-name">
                        Intermediary Dynamic Packaging
                        </div>
                      </div>
                      <div className="content">
                        Our intermediary distribution solutions include access
                        to content and rates for the full suite of non-airline
                        ancillaries for Travel Agents, OTA’s, TMC’s, Tour
                        Operators & Wholesalers.
                        <br />
                        <br />
                        <div className="liness">
                          <li className=""></li>
                          <p>  This enables Digital Dynamic Packaging – our purpose,
                          lower operating costs, servicing excellence and
                          improved revenues and margins.
                          </p>
                        </div>
                        <br />
                        We provide intermediaries with new distribution strategy
                        compliant platforms enabling access to the lowest priced
                        airline (full) content from a range of sources including
                        NDC, API’s, Direct to PSS and where necessary legacy
                        EDIFACT air content.
                        <br /> <br />
                        zen3 offers intermediaries non-air content to provide a
                        broader range of content enabling lower operating cost
                        and optimal revenues. This content can supplement your
                        existing sources.
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* Business Process */}
        <div>
          <div className="my-64">
            <div className="business-process">
              <div className="services">
                <div className="img-service">

                  <div className="horizontal-view mobile_vesrion">

                    <div className="horizontal-item horizontal-spaces mobileV">
                      <div className="travel_saas_img">
                        <h3 className="horizontal-title">business process outsourcing & offshoring</h3>
                      </div>
                      <div className="horizontal-paragraph">

                        <p className="horizontal-content">
                          zen3 has a proven track record in areas of Business
                          Process Outsourcing (BPO) and Offshore Solutions. Our
                          disciplined approach to project management ensures success for our clients. {" "}

                          {isExpandedBusinnes || (
                            <>
                              {' '}
                              <span className="readmore_button"><Link to="#" onClick={() => toggleReadMore(2)} style={{ color: "white", cursor: 'pointer' }}>
                                Read more {' '}
                                <img src={groupUp} />
                              </Link>
                              </span>
                            </>
                          )}
                          {isExpandedBusinnes && (
                            <span>
                              Our BPO practice enables
                              significant cost arbitrage in lift and shift for both
                              Near and Far Shore solutions. Lift and shift solutions
                              enable initial cost savings, and our automation of
                              previously manual business processes drives deeper
                              savings for clients.
                              <br />
                              <span>
                                <Link to="#" onClick={() => toggleReadMore(2)} style={{ color: 'white', cursor: 'pointer' }}>
                                  {' '}
                                  Read less  {' '}
                                  <img src={group} />
                                </Link>
                              </span>
                            </span>
                          )}
                        </p>

                      </div>
                      <div className="main-small-tabs">
                        <div className="small-tabs" style={{background:"#E0FEEE"}}>
                          <li className="small-tabs-text">call centres</li>
                        </div>
                        <div className="small-tabs" style={{background:"#E0FEEE"}}>
                          <li className="small-tabs-text">offshore centres</li>
                        </div>
                      </div>
                      <div className="mt-74 coonect-home-desktop">
                        <Link to="/businessprocess" style={{ textDecoration: 'none' }}>
                          <div className="btn-uparrow w-fit-content">
                            <div className="text">Learn More</div>
                            <img src={uparrowIcon} />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="horizontal-item mobileT">
                      <img src={businessProcessImg} alt="Description of the image" width={688} height={668} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Digital Transformation */}
        <div>
          <div className="my-64">
            <div className="digital-transformation">
              <div className="services">
                <div className="img-service">
                  <div className="horizontal-view mobile_vesrion">

                    <div className="horizontal-item horizontal-spaces mobileV">
                      <div className="travel_saas_img">
                        <h3 className="horizontal-title">digital transformation</h3></div>
                      <div className="horizontal-paragraph">

                        <p className="horizontal-content">
                          zen3 offers digital transformation solutions across the
                          travel distribution ecosystem. Digital transformation is
                          often defined differently by different customers, however {" "}

                          {isExpandedDigital || (
                            <>
                              {' '}
                              <span className="readmore_button"><Link to="#" onClick={() => toggleReadMore(3)} style={{ color: "white", cursor: 'pointer' }}>
                                Read more {' '}
                                <img src={groupUp} />
                              </Link>
                              </span>
                            </>
                          )}
                          {isExpandedDigital && (
                            <span>
                              the expertise of zen3 is focussed on areas of
                              Distribution, Marketing, Operations and Technology
                              services incorporating the latest developments in AI
                              (Artificial Intelligence) and ML (Machine Learning).
                              <br />
                              <span>
                                <Link to="#" onClick={() => toggleReadMore(3)} style={{ color: 'white', cursor: 'pointer' }}>
                                  {' '}
                                  Read less  {' '}
                                  <img src={group} />
                                </Link>
                              </span>
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="main-small-tabs">
                        <div className="small-tabs" style={{background:"#FFEFD4"}}>
                          <li className="small-tabs-text">Marketing</li>
                        </div>
                        <div className="small-tabs" style={{background:"#FFEFD4"}}>
                          <li className="small-tabs-text">operations</li>
                        </div>
                        <div className="small-tabs" style={{background:"#FFEFD4"}}>
                          <li className="small-tabs-text">
                            Artificial Intelligence
                          </li>
                        </div>
                        <div className="small-tabs" style={{background:"#FFEFD4"}}>
                          <li className="small-tabs-text">Machine Learning</li>
                        </div>
                      </div>
                      <div className="mt-74 coonect-home-desktop">
                        <Link to="/digital" style={{ textDecoration: 'none' }}>
                          <div className="btn-uparrow w-fit-content">
                            <div className="text">Learn More</div>
                            <img src={uparrowIcon} />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="horizontal-item mobileT">
                      <img src={digitalTramsformImg} alt="Description of the image" width={688} height={668} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Industries */}
        <div className="my-64 industires">
          <p className="titles industires-title">Industries we serve</p>
          {/* <p className="digital-content">
            zen3 offers digital transformation solutions across the travel
            distribution ecosystem. Digital transformation is often defined
            differently by different customers, however the expertise of zen3 is
            focussed on areas of distribution, marketing, operations and
            technology services incorporating the latest developments in AI
            (Artificial Intelligence) and ML (Machine Learning).
          </p> */}
          <div className="images display_desktop">
            <div className="children">
              <img src={avationImg} alt="" height="168" />
              <div className="heading">Aviation</div>
            </div>
            <div className="children">
              <img src={travelAgencyImg} alt="" height="168" />
              <div className="heading">Travel Agency Networks</div>
            </div>
            <div className="children">
              <img src={dynamicTravelImg} alt="" height="168" />
              <div className="heading">Dynamic Travel Category Packaging</div>
            </div>
            <div className="children">
              <img src={travelManagementImg} alt="" height="168" />
              <div className="heading">Travel Management Companies (TMC’s)</div>
            </div>
            <div className="children">
              <img src={cruiseImg} alt="" height="168" />
              <div className="heading">Cruise</div>
            </div>
            <div className="children">
              <img src={onlineTravelImg} alt="" height="168" />
              <div className="heading">Online Travel Agents (OTA’s)</div>
            </div>
            <div className="children">
              <img src={tourOperatorsImg} alt="" />
              <div className="heading">Tour Operators / Wholesalers</div>
            </div>
          </div>
          <div className="allimages mobile_desktop">
            <div className="allchildren">
              <img src={avationImg} alt="" height="168" />
              <div className="allheading">Aviation</div>
            </div>
            <div className="allchildren">
              <img src={travelAgencyImg} alt="" height="168" />
              <div className="allheading">Travel Agency Networks</div>
            </div>
            <div className="children">
              <img src={dynamicTravelImg} alt="" height="168" />
              <div className="allheading">Dynamic Travel Category Packaging</div>
            </div>
            <div className="allchildren">
              <img src={travelManagementImg} alt="" height="168" />
              <div className="allheading">Travel Management Companies (TMC’s)</div>
            </div>
            <div className="allchildren">
              <img src={cruiseImg} alt="" height="168" />
              <div className="allheading">Cruise</div>
            </div>
            <div className="allchildren">
              <img src={onlineTravelImg} alt="" height="168" />
              <div className="allheading">Online Travel Agents (OTA’s)</div>
            </div>
            <div className="allchildren">
              <img src={tourOperatorsImg} alt="" />
              <div className="allheading">Tour Operators / Wholesalers</div>
            </div>
          </div>
        </div>
        {/* zen3 card */}
        <div className="zen3-card">
          <div className="card">
            <p className="heading">
              zen3 is your trusted solutions and business partner to take your
              travel business to the next level
            </p>
            <a href="mailto:enquiries@zen3.com" style={{ textDecoration: 'none' }}>
              <div className="btn-uparrow w-fit-content text">
                <div className="">Connect Now</div>
                <img src={uparrowIcon} />
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="main-footer">
        <Footer />
      </div>
    </>
  );
};

export default WhoAreWe;
